import { IconPlus } from 'assets/images'
import { Container, Loading, PageHeader } from 'components'
import { useRole } from 'hooks'
import { t } from 'i18n'
import { debounce } from 'lodash'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { If } from 'react-extras'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { postReport } from 'services'
import {
  AuthState,
  PersonalInformationConsent,
  Report,
  ReportSection,
  ReportState,
  ReportStatus,
  Role
} from 'store/ducks'
import * as ReportActions from 'store/ducks/report/actions'
import { ApplicationState } from '../../../store'
import ReportTable from './ReportTable'
import {
  Button,
  FilterContainer,
  NavButton,
  NavButtonContainer,
  NavContainer,
  SearchInput,
  StyledDropDown
} from './styled'

interface IReportLandingPageProps {
  report: ReportState
  auth: AuthState
  clearReport: () => void
}

const defaultFilter = {
  status: ReportStatus.ALL,
  search: ''
}

type FilterData = typeof defaultFilter

export enum ReportList {
  USER = 'USER_REPORT_LIST',
  RESORT = 'RESORT_REPORT_LIST'
}

const extractLatLng = ({
  coords: { latitude: lat, longitude: lng }
}: {
  coords: { latitude: number; longitude: number }
}) => ({
  lat,
  lng
})

const getUserLocation = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 60000
      }
      navigator.geolocation.getCurrentPosition(
        position => resolve(extractLatLng(position)),
        error => reject(error.code),
        options
      )
    } else {
      reject(new Error('Geolocation not supported'))
    }
  })

const getUserLocationSilently = async () => {
  try {
    const { lat, lng } = (await getUserLocation()) as any
    return { lat, lng }
  } catch (error) {
    return { lat: null, lng: null }
  }
}

const ReportLandingPage = ({
  report,
  auth,
  clearReport
}: IReportLandingPageProps) => {
  const [filterData, setFilterData] = useState<FilterData>(defaultFilter)
  const [searchKey, setSearchKey] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeReportList, setActiveReportList] = useState<ReportList>(
    ReportList.USER
  )

  const navigate = useNavigate()

  const isResortAdmin = useRole([Role.RESORT_ADMINISTRATOR])
  const isSysAdmin = useRole([Role.SYS_ADMIN])

  const { loading } = report

  const { resort: { defaultSeason } = {} } = auth.data

  const createReport = async (type: ReportSection) => {
    setIsLoading(true)
    clearReport()

    const { lat, lng } = await getUserLocationSilently()

    let userLocation = {}

    if (lat && lng) {
      userLocation = {
        latitude: lat,
        longitude: lng
      }
    }

    const report: Report = {
      currentStep: 1,
      pictures: [],
      audio: [],
      version: 1,
      subStep: 0,
      personalInfoCompleted: false,
      injuryCompleted: false,
      sequenceOfEventsCompleted: false,
      checklistCompleted: false,
      timeStampsCompleted: false,
      stateCompleted: false,
      transportationCompleted: false,
      treatedByCompleted: false,
      surveillanceCompleted: false,
      reportDate: new Date().toISOString(),
      sequenceOfEvents: {
        pictures: []
      },
      personalInformation: {
        permissionDenied: false,
        personalInfoFormCompleted: false,
        personalInformationConsent:
          PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT
      },
      timestamps: {
        notificationOfInjuryTime: moment().format('HH:mm')
      },
      isArchive: true,
      archive: {},
      injury: {
        assumedInjury: {
          other: false
        },
        injuredBodyPart: {
          other: false
        }
      },
      transport: {
        transportAtResort: {},
        furtherTransport: {}
      },
      additionalCreators: [],
      relatedReports: [],
      investigationAndMonitoring: {
        currentMonitoring: {},
        monitoring: []
      },
      investigationAndMonitoringCompleted: false,
      section: type,
      season: defaultSeason as any,
      userLocation,
      metadata: {
        appVersion: 'web-app'
      }
    }

    try {
      const newReport = await postReport(report)
      setIsLoading(false)
      return navigate(`edit/${newReport.reportId}`)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const handleChangeReportList = (listType: ReportList) => {
    setFilterData(defaultFilter)
    setActiveReportList(listType)
  }
  const isUserList = activeReportList === ReportList.USER
  const isResortList = activeReportList === ReportList.RESORT

  const status = [
    {
      label: t('all'),
      value: ReportStatus.ALL
    },
    {
      label: t('published'),
      value: ReportStatus.PUBLISHED
    },
    {
      label: t('archived'),
      value: ReportStatus.ARCHIVED
    },
    {
      label: t('draft'),
      value: ReportStatus.DRAFT
    }
  ]

  const debouncedSave = useRef(
    debounce(nextValue => setSearchKey(nextValue), 500)
  ).current

  const handleOnChange = (
    e: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { value, name } = e.currentTarget

    setFilterData({
      ...defaultFilter,
      [name]: value
    })

    if (name === 'search') {
      debouncedSave(value)
    }
  }

  if (isLoading || loading) return <Loading />

  return (
    <Container>
      <PageHeader title={t('reportPage')}>
        <Button onClick={() => createReport(ReportSection.REPORT)}>
          {t`createNewReport`}
          <IconPlus />
        </Button>
      </PageHeader>
      <NavButtonContainer>
        <NavButton
          onClick={() => handleChangeReportList(ReportList.USER)}
          $isActive={isUserList}
        >
          {t('myReports')}
        </NavButton>
        <If condition={isResortAdmin || isSysAdmin}>
          <NavButton
            onClick={() => handleChangeReportList(ReportList.RESORT)}
            $isActive={isResortList}
          >
            {t('resortReports')}
          </NavButton>
        </If>
      </NavButtonContainer>
      <NavContainer>
        <SearchInput
          type="text"
          name="search"
          id="search"
          value={filterData.search}
          onChange={handleOnChange}
          placeholder={t('searchReportNumberPatientName')}
        />
        <FilterContainer>
          <StyledDropDown
            $border={false}
            items={status}
            name="status"
            onChange={handleOnChange}
            value={filterData.status}
          />
        </FilterContainer>
      </NavContainer>
      <ReportTable
        reportStatus={filterData.status}
        searchKey={searchKey.toLowerCase()}
        activeReportList={activeReportList}
      />
    </Container>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  report: state.report,
  auth: state.auth
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReportActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportLandingPage)
