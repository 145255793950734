import { TimeInput } from 'components'
import { useFormikContext } from 'formik'
import { If } from 'react-extras'
import { Report, Seasons } from 'store/ducks'
import FormTitle from '../FormTitle'
import { FlexRow, RowItem } from '../styled'

const TimeStampsSection = () => {
  const {
    values: { timestamps, season },
    setFieldValue
  } = useFormikContext<Report>()

  return (
    <>
      <FormTitle title="timeStamps" />
      <FlexRow>
        <RowItem width={25}>
          <TimeInput
            name="timestamps.notificationOfInjuryTime"
            label="notificationOfInjury"
            value={timestamps?.notificationOfInjuryTime}
            onChange={setFieldValue}
          />
          <If condition={season === Seasons.WINTER}>
            <TimeInput
              name="timestamps.skiPatrolAtInjurySite"
              label="skiPatrolOnInjurySite"
              value={timestamps?.skiPatrolAtInjurySite}
              onChange={setFieldValue}
            />
          </If>
          <If condition={season === Seasons.SUMMER}>
            <TimeInput
              name="timestamps.pathPatrolAtInjurySite"
              label="pathPatrolAtTheInjurySite"
              value={timestamps?.pathPatrolAtInjurySite}
              onChange={setFieldValue}
            />
          </If>
          <TimeInput
            name="timestamps.pulkArrivedTime"
            label="pulkArrived"
            value={timestamps?.pulkArrivedTime}
            onChange={setFieldValue}
          />
          <TimeInput
            name="timestamps.departureFromInjurySiteTime"
            label="departureFromInjurySite"
            value={timestamps?.departureFromInjurySiteTime}
            onChange={setFieldValue}
          />
          <TimeInput
            name="timestamps.arrivedAtInjuryRoomTime"
            label="Arrived"
            value={timestamps?.arrivedAtInjuryRoomTime}
            onChange={setFieldValue}
          />
          <TimeInput
            name="timestamps.amkWarningTime"
            label="amkWarning"
            value={timestamps?.amkWarningTime}
            onChange={setFieldValue}
          />
          <TimeInput
            name="timestamps.patientDeliveredTime"
            label="patientDelivered"
            value={timestamps?.patientDeliveredTime}
            onChange={setFieldValue}
          />
        </RowItem>
      </FlexRow>
    </>
  )
}

export default TimeStampsSection
