import { Media } from 'store/ducks'

interface VideoPlayerProps {
  audio: Media
  width?: string
  height?: string
  controls?: boolean
}

const VideoPlayer = ({
  audio,
  width = '120px',
  height = '120px',
  controls = true
}: VideoPlayerProps) => {
  return (
    <video width={width} height={height} controls={controls}>
      <source src={audio.url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}

export { VideoPlayer }
