/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppBar, Box, Dialog, IconButton, Toolbar } from '@mui/material'
import { IconClose } from 'assets/images'
import { COLUMNS } from 'constants/common'
import { StatisticPageContext } from 'context/StatisticPageContext'
import { t } from 'i18n'
import { isEmpty } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState } from 'store'
import { Coordinate, Resort, Seasons } from 'store/ducks'
import * as ResortActions from 'store/ducks/resort/actions'
import styled from 'styled-components'
import {
  FullImgPreview,
  NoImageBox,
  SmallImgPreview,
  StyledLoading
} from '../Slopes/styled'

interface Props {
  resort: Resort
  getResortRequest: (id: string | undefined) => void
  season: string
  isLoading: boolean
}

type ImageDimensions =
  | {
      width: number
      height: number
    }
  | Record<string, never>

const ImageWrapper = styled(Box)<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  max-height: 100%;
  background-color: #eeeeee;
  margin-bottom: 2rem;
  position: relative;
`

const StyledCircle = styled.div<{
  $circle: number
  $top: number
  $left: number
}>`
  z-index: 1;
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  width: ${({ $circle }) => $circle}px;
  height: ${({ $circle }) => $circle}px;
  background-color: red;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 12px;
  color: white;
`

const RenderCoordinates = ({
  coordinates,
  cellSize,
  flag = false
}: {
  coordinates: Coordinate[]
  cellSize: number
  flag?: boolean
}) => {
  const uniqueCoordinates = coordinates.filter(
    (item, index, self) =>
      index === self.findIndex(t => t.x === item.x && t.y === item.y)
  )

  if (isEmpty(coordinates)) return null
  return (
    <>
      {uniqueCoordinates.map((coordinate, index) => {
        const itemToFind = { x: coordinate.x, y: coordinate.y }
        if (!itemToFind.x || !itemToFind.y) return null
        if (itemToFind.x == null || itemToFind.y == null) return null
        const sameItems = coordinates.filter(
          item => item.x === itemToFind.x && item.y === itemToFind.y
        )
        const additionalPixels = sameItems.length * 3
        const circle = cellSize + additionalPixels
        const top = coordinate.y * cellSize - additionalPixels / 2
        const left = coordinate.x * cellSize - additionalPixels / 2
        return (
          <StyledCircle key={index} $circle={circle} $top={top} $left={left}>
            {flag && sameItems.length}
          </StyledCircle>
        )
      })}
    </>
  )
}

const Heatmap = ({ resort, getResortRequest, season, isLoading }: Props) => {
  const [preview, setPreview] = useState('')
  const [loading, setLoading] = useState(false)
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({})
  const [open, setOpen] = useState(false)
  const [displayWidth, setDisplayWidth] = useState(450)
  const { coordinatesByFilteredReports, filterData } =
    useContext(StatisticPageContext)

  let fileName = ''
  let coordinates = [] as Coordinate[]
  if (season === Seasons.WINTER.toLowerCase()) {
    fileName = resort?.heatmapBySeasons?.winter?.fileName || ''
    coordinates = resort?.heatmapBySeasons?.winter?.coordinates || []
  } else if (season === Seasons.SUMMER.toLowerCase()) {
    coordinates =
      resort?.heatmapBySeasons?.winter?.coordinates?.filter(
        (coordinate: { reportId: number }) =>
          coordinatesByFilteredReports[season as 'summer' | 'winter'].includes(
            coordinate.reportId
          )
      ) || []
  }
  if (season === Seasons.SUMMER.toLowerCase()) {
    fileName = resort?.heatmapBySeasons?.summer?.fileName || ''
    coordinates = resort?.heatmapBySeasons?.summer?.coordinates || []
    coordinates =
      resort?.heatmapBySeasons?.summer?.coordinates?.filter(
        (coordinate: { reportId: number }) =>
          coordinatesByFilteredReports[season as 'summer' | 'winter'].includes(
            coordinate.reportId
          )
      ) || []
  }

  useEffect(() => {
    if (filterData.resort === 'ALL') return
    getResortRequest(filterData.resort)
  }, [filterData.resort, getResortRequest])

  useEffect(() => {
    if (open) {
      const handleResize = () => {
        const originalImageWith = imageDimensions.width
        const windowWidth = window.innerWidth
        const width =
          originalImageWith >= windowWidth ? windowWidth : originalImageWith
        setDisplayWidth(width)
      }

      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [imageDimensions.width, open])

  const handleUpload = useCallback(async () => {
    setLoading(true)
    if (fileName) {
      try {
        const url =
          resort?.heatmapBySeasons?.[season as 'summer' | 'winter']?.url || ''
        setPreview(url)
        const img = new Image()
        img.src = url

        img.onload = () => {
          setImageDimensions({
            height: img.height,
            width: img.width
          })
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: 'top-right'
        })
      } finally {
        setLoading(false)
      }
    }
  }, [fileName, resort?.heatmapBySeasons, season])

  useEffect(() => {
    const handleUploadData = async () => {
      await handleUpload()
    }

    handleUploadData().catch(error => {
      console.error(error)
    })
  }, [handleUpload])

  if (!imageDimensions.width || !imageDimensions.height) return null

  const handleClickOpen = () => {
    setOpen(true)
    const originalImageWith = imageDimensions.width
    const windowWidth = window.innerWidth
    const width =
      originalImageWith >= windowWidth ? windowWidth : originalImageWith
    setDisplayWidth(width)
  }

  const handleClose = () => {
    setOpen(false)
    setDisplayWidth(450)
  }

  const cellSize = displayWidth / COLUMNS
  if (filterData.resort === 'ALL') return null
  return (
    <div>
      <h4>{t('heatmap', { season: t(season) })}</h4>
      <ImageWrapper $width={displayWidth}>
        {(loading || isLoading) && <StyledLoading />}
        {preview ? (
          <>
            <Dialog open={open} fullScreen>
              <AppBar
                sx={{
                  position: 'relative',
                  flexDirection: 'row',
                  paddingLeft: '2rem'
                }}
              >
                <h4>{t('heatmap', { season: t(season) })}</h4>
                <Toolbar style={{ marginLeft: 'auto' }}>
                  <IconButton
                    autoFocus
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <IconClose />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <div
                style={{
                  display: 'flex',
                  margin: '0 auto',
                  position: 'relative'
                }}
              >
                <FullImgPreview src={preview} alt={t('preview')} />
                <RenderCoordinates
                  coordinates={coordinates}
                  cellSize={cellSize}
                  flag
                />
              </div>
            </Dialog>
            <div onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
              <SmallImgPreview
                src={preview}
                alt={t('preview')}
                style={{ position: 'relative' }}
              />
              <RenderCoordinates
                coordinates={coordinates}
                cellSize={cellSize}
              />
            </div>
          </>
        ) : (
          <NoImageBox>
            <span>{t('noImage')}</span>
          </NoImageBox>
        )}
      </ImageWrapper>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  resort: state.resort.data,
  isLoading: state.resort.loading
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ResortActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Heatmap)
