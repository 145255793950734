import { IconPrint } from 'assets/images'
import { Loading } from 'components'
import { PageTitle } from 'components/PageHeader/styled'
import { t } from 'i18n'
import { useContext } from 'react'
import { Seasons } from 'store/ducks'
import styled from 'styled-components'
import theme from 'styles/theme'
import { validate as hasSelectedIndividualResort } from 'uuid'
import Charts from '../../containers/Statistics/Charts'
import SimpleStatistic from '../../containers/Statistics/Simple'
import StatisticTable from '../../containers/Statistics/Table'
import { StatisticPageContext } from '../../context/StatisticPageContext'
import Heatmap from './heatmap'

export enum BUTTON_COLOR {
  RED = 'red',
  BLUE = 'blue'
}

export const StyledButton = styled.button<any>`
  width: 100%;
  padding: 1rem;
  text-align: left;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;

  background-color: ${({ theme, $bgColor, $bordered }) =>
    $bordered ? 'transparent' : theme.colors[$bgColor]};
  color: ${({ theme, $bordered }) =>
    $bordered ? '#0072bb' : theme.colors.white};
  border: ${`2px solid #0072bb`};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({ $bordered }) =>
      $bordered ? '#0072bb' : 'transparent'};
    color: ${({ theme, $bordered }) =>
      $bordered ? theme.colors.white : '#0072bb'};
    border: ${({ theme, $bgColor }) => `2px solid ${theme.colors[$bgColor]}`};

    svg {
      * {
        fill: ${({ theme, $bordered }) =>
          $bordered ? theme.colors.white : '#0072bb'};
      }
    }
  }

  svg {
    height: 20px;
    * {
      fill: ${({ theme, $bordered }) =>
        $bordered ? '#0072bb' : theme.colors.white};
      border: none;
    }
  }
`

const SimpleStatisticContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0;
  padding: 40px;
`

const ContentContainer = styled.div`
  padding: 1rem;
  border: 2px solid #0072bb;
`

const TabContainer = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  margin-bottom: -2px;
`

const Tab = styled.li`
  padding: 1rem;
  cursor: pointer;
  background-color: #0072bb;
  border: 2px solid #0072bb;
  color: white;
  border-bottom: 0;
  &.active {
    background-color: white;
    color: ${theme.colors.primary};
  }
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`

const StatisticsPage = () => {
  const {
    renderFilters,
    isLoading,
    statistics,
    activeTab,
    setActiveTab,
    isDownloading,
    downloadFile,
    filterData
  } = useContext(StatisticPageContext)
  const commonPlace = statistics?.[activeTab]?.commonPlace
  const totalInjuries = statistics?.[activeTab]?.totalInjuries
  const injuriesBySlope = statistics?.[activeTab]?.injuriesBySlope
  const injuriesInSlopesData = injuriesBySlope
    ?.sort((a, b) => b.total - a.total)
    .map((item, index) => ({
      ...item,
      index: index + 1,
      commonInjury: t(item.commonInjury)
    }))

  const injuriesInSlopesColumns = [
    {
      path: 'index'
    },
    {
      path: 'name',
      label: t('slope')
    },
    {
      path: 'commonInjury',
      label: t('commonInjury')
    },
    {
      path: 'total',
      label: t('totalInjuries')
    }
  ]

  const handleOnClick = (season: Seasons) => {
    return setActiveTab(season)
  }

  return (
    <Wrapper>
      <PageTitle>{t('statistics')}</PageTitle>

      {renderFilters()}
      <TabContainer>
        {statistics?.[Seasons.WINTER]?.totalInjuries && (
          <Tab
            className={activeTab === Seasons.WINTER ? 'active' : ''}
            onClick={() => handleOnClick(Seasons.WINTER)}
          >
            {t('winter')}
          </Tab>
        )}
        {statistics?.[Seasons.SUMMER]?.totalInjuries && (
          <Tab
            className={activeTab === Seasons.SUMMER ? 'active' : ''}
            onClick={() => handleOnClick(Seasons.SUMMER)}
          >
            {t('summer')}
          </Tab>
        )}
      </TabContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {(statistics?.[Seasons.WINTER]?.totalInjuries ||
            statistics?.[Seasons.SUMMER]?.totalInjuries) && (
            <ContentContainer>
              {activeTab === Seasons.WINTER && (
                <Heatmap season={Seasons.WINTER.toLowerCase()} />
              )}
              {activeTab === Seasons.SUMMER && (
                <Heatmap season={Seasons.SUMMER.toLowerCase()} />
              )}
              {!isLoading && (
                <SimpleStatisticContainer>
                  <SimpleStatistic
                    label={t('totalInjuries')}
                    value={totalInjuries || 0}
                  />
                  {commonPlace && (
                    <SimpleStatistic
                      label={t('commonAccidentSite')}
                      value={t(commonPlace)}
                    />
                  )}
                  {hasSelectedIndividualResort(filterData.resort as string) && (
                    <StyledButton
                      disabled={isDownloading}
                      onClick={() => downloadFile(activeTab)}
                      $bgColor={BUTTON_COLOR.BLUE}
                      $bordered
                    >
                      {t('export')}
                      <IconPrint fill="#0072bb" />
                    </StyledButton>
                  )}
                </SimpleStatisticContainer>
              )}
              <Charts />
              {injuriesInSlopesData?.length > 0 && (
                <StatisticTable
                  header={t('injuriesInSlope')}
                  subheader={`${t('top')} ${injuriesInSlopesData.length}`}
                  data={injuriesInSlopesData}
                  columns={injuriesInSlopesColumns}
                />
              )}
            </ContentContainer>
          )}
        </>
      )}
      {!statistics?.[Seasons.WINTER]?.totalInjuries &&
        !statistics?.[Seasons.SUMMER]?.totalInjuries &&
        !isLoading && <h3>{t('noAvailableStatistics')}</h3>}
    </Wrapper>
  )
}

export default StatisticsPage
