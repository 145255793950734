import { action } from 'typesafe-actions'
import { Resort } from '../report/types'
import { GetResortTypes } from './types'

export const getResortRequest = (id: string | undefined) =>
  action(GetResortTypes.GET_RESORT_REQUEST, id)

export const getResortSuccess = (data: Resort) =>
  action(GetResortTypes.GET_RESORT_SUCCESS, data)

export const getResortFailure = (error: Error) =>
  action(GetResortTypes.GET_RESORT_FAILURE, { errorStack: error })
