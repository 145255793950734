import { IconClose, IconPaperclip } from 'assets/images'
import { AudioPlayer, Button, ImageGalleryItem, VideoPlayer } from 'components'
import { useFormikContext } from 'formik'
import { t } from 'i18n'
import { useState } from 'react'
import { Media, Report, SectionKey } from 'store/ducks'
import { ModalOverlay } from '../../../../../styles/global'
import { ModalCloseIcon } from '../../form-components/FormTitle/styled'
import {
  AllFilesContainer,
  AllFilesModalContainer,
  AllFilesSection
} from './styled'

const AllFiles = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { values } = useFormikContext<Report>()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const getImages = (sectionKey: SectionKey) => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values.sequenceOfEvents?.pictures
      case SectionKey.INJURY:
        return values.injury?.pictures
      default:
        return values?.pictures
    }
  }

  const getRecordings = (sectionKey: SectionKey) => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values?.sequenceOfEvents?.recordings
      case SectionKey.INJURY:
        return values?.injury?.recordings
      default:
        return values?.audio
    }
  }

  const getVideos = (sectionKey: SectionKey) => {
    switch (sectionKey) {
      case SectionKey.SEQUENCE_OF_EVENTS:
        return values?.sequenceOfEvents?.videos
      case SectionKey.INJURY:
        return values?.injury?.videos
      default:
        return values?.videos
    }
  }

  const renderImageGallery = (images?: Media[]) => {
    if (!images || images.length === 0) return <h3>{t`noImages`}</h3>
    return (
      <>
        {images.map((image, index) => (
          <ImageGalleryItem key={index} path={image.path} />
        ))}
      </>
    )
  }

  const renderRecordings = (recordings?: Media[]) => {
    if (!recordings || recordings.length === 0)
      return <h3>{t`noRecordings`}</h3>
    return (
      <>
        {recordings.map((recording, index) => (
          <AudioPlayer key={index} audio={recording} />
        ))}
      </>
    )
  }

  const renderVideos = (videos?: Media[]) => {
    if (!videos || videos.length === 0) return <h3>{t`noVideos`}</h3>
    return (
      <>
        {videos.map((video, index) => (
          <VideoPlayer key={index} audio={video} />
        ))}
      </>
    )
  }

  const sequenceOfEventsImages = getImages(SectionKey.SEQUENCE_OF_EVENTS)
  const sequenceOfEventsRecordings = getRecordings(
    SectionKey.SEQUENCE_OF_EVENTS
  )
  const sequenceOfEventsVideos = getVideos(SectionKey.SEQUENCE_OF_EVENTS)

  const injuryImages = getImages(SectionKey.INJURY)
  const injuryRecordings = getRecordings(SectionKey.INJURY)
  const injuryVideos = getVideos(SectionKey.INJURY)

  const generalImages = getImages(SectionKey.GENERAL)
  const generalRecordings = getRecordings(SectionKey.GENERAL)
  const generalVideos = getVideos(SectionKey.GENERAL)

  const renderSectionGallery = (
    title: string,
    images?: Media[],
    recordings?: Media[],
    generalVideos?: Media[]
  ) => {
    return (
      <>
        <h2>{t(title)}</h2>
        <AllFilesContainer>
          <AllFilesSection style={{ flexWrap: 'wrap' }}>
            {renderImageGallery(images)}
          </AllFilesSection>
          <AllFilesSection>{renderRecordings(recordings)}</AllFilesSection>
          <AllFilesSection style={{ flexWrap: 'wrap' }}>
            {renderVideos(generalVideos)}
          </AllFilesSection>
        </AllFilesContainer>
      </>
    )
  }

  return (
    <>
      <Button
        type="button"
        variant="secondary"
        textSize="small"
        label="showAddedFiles"
        icon={<IconPaperclip />}
        onClick={toggleOpen}
      />
      {open && (
        <ModalOverlay>
          <AllFilesModalContainer
            style={{
              overflowY: 'initial'
            }}
          >
            <ModalCloseIcon>
              <IconClose onClick={toggleOpen} />
            </ModalCloseIcon>
            {renderSectionGallery(
              'sequenceOfEvents',
              sequenceOfEventsImages,
              sequenceOfEventsRecordings,
              sequenceOfEventsVideos
            )}
            {renderSectionGallery(
              'injury',
              injuryImages,
              injuryRecordings,
              injuryVideos
            )}
            {renderSectionGallery(
              'generalInfo',
              generalImages,
              generalRecordings,
              generalVideos
            )}
          </AllFilesModalContainer>
        </ModalOverlay>
      )}
    </>
  )
}

export default AllFiles
