import { FilterDate, Gender } from 'services'
import { Seasons } from 'store/ducks'
import alpha3List from '../../../assets/locales/alpha3.nationalities.json'

type Translation = (x: string) => string

export const gender = (t: Translation) => [
  {
    label: t('all'),
    value: Gender.ALL
  },
  {
    label: t('male'),
    value: Gender.MALE
  },
  {
    label: t('female'),
    value: Gender.FEMALE
  }
]

export const age = [
  {
    label: '1 - 100',
    value: '1,100'
  },
  {
    label: '1 - 10',
    value: '1,10'
  },
  {
    label: '11 - 20',
    value: '11,20'
  },
  {
    label: '21 - 30',
    value: '21,30'
  },
  {
    label: '31 - 40',
    value: '31,40'
  },
  {
    label: '41 - 50',
    value: '41,50'
  },
  {
    label: '51 - 60',
    value: '51,60'
  },
  {
    label: '61 - 70',
    value: '61,70'
  },
  {
    label: '71 - 80',
    value: '71,80'
  },
  {
    label: '81 - 90',
    value: '81,90'
  },
  {
    label: '91 - 100',
    value: '91,100'
  }
]

export const seasons = (t: Translation) => [
  {
    label: t('winter'),
    value: Seasons.WINTER
  },
  {
    label: t('summer'),
    value: Seasons.SUMMER
  }
]

export const nationalities = (t: Translation) => {
  const all = { label: '', id: '' }
  const nationalities = alpha3List
    .map(item => ({
      label: t(item),
      id: item
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return [{ ...all }, ...nationalities]
}

export const dates = (t: Translation) => [
  // {
  //   label: t('today'),
  //   value: FilterDate.TODAY
  // },
  // {
  //   label: t('thisWeek'),
  //   value: FilterDate.THIS_WEEK
  // },
  // {
  //   label: t('thisMonth'),
  //   value: FilterDate.THIS_MONTH
  // },
  {
    label: t('thisYear'),
    value: FilterDate.THIS_YEAR
  },
  {
    label: '2022',
    value: 2022
  }
]
