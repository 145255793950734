import { AxiosResponse } from 'axios'
import { Resort } from 'store/ducks'
import api from './baseApi'

export const getResortList = async () => {
  const response: AxiosResponse<Resort[]> = await api.get('resort')
  return response.data
}

export const getResort = async (id: string | undefined) => {
  const response: AxiosResponse<Resort> = await api.get(`resort/${id}`)
  return response.data
}
