import { Loading } from 'components'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Dispatch, bindActionCreators } from 'redux'
import { Report, ReportState } from 'store/ducks'
import * as ReportActions from 'store/ducks/report/actions'
import { ApplicationState } from '../../../store'
import ReportFormStep1 from '../components/form-components/Step1'
import ReportFormStep2 from '../components/form-components/Step2'
import ReportFormStep3 from '../components/form-components/Step3'
import ReportFormStep4 from '../components/form-components/Step4'
import ReportHeader from '../components/report-header'
import { ValidateReportSections } from './report-fill-validations'

interface IReportNewProps {
  report: ReportState
  getReportRequestOverride: (reportId: number) => void
  createReportRequest: (data: Report) => void
}

const ReportNew = ({
  report,
  getReportRequestOverride,
  createReportRequest
}: IReportNewProps) => {
  const [formStep, setFormStep] = useState<number>(1)

  const { reportId } = useParams()
  const { loading, data } = report

  const navigate = useNavigate()

  useEffect(() => {
    getReportRequestOverride(Number(reportId))
  }, [getReportRequestOverride, reportId])

  const handleSubmit = (values: Report) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    values = ValidateReportSections(values)

    if (formStep !== 4) {
      createReportRequest(values)
    }

    switch (formStep) {
      case 1:
        setFormStep(2)
        return
      case 2:
        setFormStep(3)
        return
      case 3:
        setFormStep(4)

        return
      case 4:
        values.reportStatus = 'PUBLISHED'
        createReportRequest(values)
        navigate('/')
        return

      default:
        return
    }
  }

  const handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    switch (formStep) {
      case 1:
        setFormStep(1)
        return
      case 2:
        setFormStep(1)
        return
      case 3:
        setFormStep(2)
        return
      case 4:
        setFormStep(3)
        return
      default:
        return
    }
  }

  if (loading || !data.personalInformation) return <Loading />
  return (
    <Formik initialValues={data} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <ReportHeader injuryReportNumber={data.SK} formStep={formStep} />
          {formStep === 1 && <ReportFormStep1 />}
          {formStep === 2 && <ReportFormStep2 handleBack={handleBack} />}
          {formStep === 3 && (
            <ReportFormStep3
              handleBack={handleBack}
              setFormStep={setFormStep}
            />
          )}
          {formStep === 4 && (
            <ReportFormStep4
              handleBack={handleBack}
              setFormStep={setFormStep}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  report: state.report
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReportActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportNew)
