import { Resort } from '../report/types'

export enum GetResortTypes {
  GET_RESORT_REQUEST = '@resort/GET_RESORT_REQUEST',
  GET_RESORT_SUCCESS = '@resort/GET_RESORT_SUCCESS',
  GET_RESORT_FAILURE = '@resort/GET_RESORT_FAILURE'
}

export interface ResortState {
  readonly data: Resort
  readonly loading: boolean
  readonly error: boolean
  readonly errorStack: any
}
