const GENDER_OPTIONS = [
  {
    id: '',
    label: ''
  },
  {
    id: 'male',
    label: 'Male'
  },
  {
    id: 'female',
    label: 'Female'
  }
]

const RELATIVE_OPTIONS = [
  {
    id: '',
    label: ''
  },
  {
    id: 'Parents',
    label: 'Parents'
  },
  {
    id: 'Cohabitant',
    label: 'Cohabitant'
  },
  {
    id: 'Spouse',
    label: 'Spouse'
  },
  {
    id: 'Other',
    label: 'Other'
  }
]

const SKILL_LEVEL_OPTIONS = [
  {
    id: 'EXPERT',
    label: 'expert'
  },
  {
    id: 'GOOD',
    label: 'good'
  },
  {
    id: 'INTERMEDIATE',
    label: 'intermediate'
  },
  {
    id: 'BEGINNER',
    label: 'beginner'
  }
]

const BOOLEAN_OPTIONS = [
  {
    id: true,
    label: 'yes'
  },
  {
    id: false,
    label: 'no'
  }
]

const IS_RENTED_OPTIONS = [
  {
    id: 'Yes',
    label: 'yes'
  },
  {
    id: 'no',
    label: 'no'
  },
  {
    id: 'Dont know',
    label: 'Dont know'
  }
]

const HIGH_ENERGY_OPTIONS = [
  {
    id: 'Yes',
    label: 'yes'
  },
  {
    id: 'No',
    label: 'no'
  },
  {
    id: 'Unknown',
    label: 'unknown'
  }
]

const CONSCIOUSNESS_OPTIONS = [
  {
    id: 'Awaked and clear',
    label: 'awakeAndClear'
  },
  {
    id: 'Awaked and unclear',
    label: 'awakeAndUnclear'
  },
  {
    id: 'Reacts to pain',
    label: 'reactsToPain'
  },
  {
    id: "Doesn't react",
    label: 'doesntReact'
  }
]

const SKI_EQUIPMENT_OPTIONS = [
  {
    id: 'Slalom Ski',
    label: 'slalomSki'
  },
  {
    id: 'Telemark',
    label: 'telemark'
  },
  {
    id: 'Snowboard',
    label: 'snowboard'
  },
  {
    id: 'Crosscountry ski',
    label: 'crosscountrySki'
  }
]
const BIKE_EQUIPMENT_OPTIONS = [
  {
    id: 'Balanced bike',
    label: 'balanced bike'
  },
  {
    id: 'Childrens bike',
    label: 'childrens bike'
  },
  {
    id: 'Half muted',
    label: 'half muted'
  },
  {
    id: 'Fully damped',
    label: 'fully damped'
  },
  {
    id: 'Other',
    label: 'other'
  }
]

const BREATHING_OPTIONS = [
  {
    id: 'Unaffected',
    label: 'unaffected'
  },
  {
    id: 'Strained',
    label: 'strained'
  },
  {
    id: 'Painful while taking a breath',
    label: 'painfulWhileTakingABreath'
  },
  {
    id: 'Other',
    label: 'other'
  }
]

const PULSE_OPTIONS = [
  {
    id: 'Normal pulse',
    label: 'normalPulse'
  },
  {
    id: 'Weak pulse',
    label: 'weakPulse'
  },
  {
    id: 'Cant detect pulse',
    label: 'cantDetectPulse'
  }
]

const PULSE_SOURCE_OPTIONS = [
  {
    id: 'throat',
    label: 'throat'
  },
  {
    id: 'wrist',
    label: 'wrist'
  }
]

const BLEEDINGS_SOURCE_OPTIONS = [
  {
    id: 'internal',
    label: 'Internal'
  },
  {
    id: 'external',
    label: 'External'
  }
]

const CIRCULATION_OPTIONS = [
  {
    id: 'pale',
    label: 'pale'
  },
  {
    id: 'clammyCold',
    label: 'clammy/cold'
  },
  {
    id: 'thirsty',
    label: 'thirsty'
  }
]

const INJURY_LOCATION_OPTIONS = [
  {
    id: 'terrainParkAtElement',
    label: 'terrainParkAtElement'
  },
  {
    id: 'preparedDownSpeed',
    label: 'preparedDownspeed'
  },
  {
    id: 'outsidePreparedDownSpeed',
    label: 'outsidePreparedDownspeed'
  },
  {
    id: 'upwardsLift',
    label: 'upwardsLift'
  },
  {
    id: 'whenBoarding',
    label: 'whenBoarding'
  },
  {
    id: 'whenDisembarking',
    label: 'whenDisembarking'
  },
  {
    id: 'closedDownSpeed',
    label: 'closedDownspeed'
  },
  { id: 'crosscountryTrack', label: 'crosscountryTrack' }
]

const INJURY_LOCATION_SUMMER_OPTIONS = [
  {
    id: 'adaptedPath',
    label: 'adaptedPath'
  },
  {
    id: 'naturalPath',
    label: 'naturalPath'
  },
  {
    id: 'roadGravelRoad',
    label: 'roadGravelRoad'
  },
  {
    id: 'jump',
    label: 'jump'
  },
  {
    id: 'pumptrack',
    label: 'pumptrack'
  },
  {
    id: 'tractionElement',
    label: 'tractionElement'
  },
  { id: 'crosscountryTrack', label: 'crosscountryTrack' }
]

const SEQUENCE_OF_EVENTS_WINTER = [
  {
    id: 'fallYourself',
    label: 'fallYourself'
  },
  {
    id: 'hitByAnotherPerson',
    label: 'hitByAnotherPerson'
  },
  {
    id: 'runOverAnotherPerson',
    label: 'runOverAnotherPerson'
  },
  {
    id: 'collisionWithObject',
    label: 'collisionWithObject'
  }
]

const SEQUENCE_OF_EVENTS_SUMMER = [
  {
    id: 'lostControl',
    label: 'lostControl'
  },
  {
    id: 'drivenOutOfBerm',
    label: 'drivenOutOfBerm'
  },
  {
    id: 'crashObjectOnTrail',
    label: 'crashObjectOnTrail'
  },
  {
    id: 'lostBalanceAfterJump',
    label: 'lostBalanceAfterJump'
  },
  {
    id: 'hitByAnotherCyclist',
    label: 'hitByAnotherCyclist'
  }
]

const POSIBLE_INJURY_OPTIONS = [
  {
    id: 'headInjury',
    label: 'headInjury'
  },
  {
    id: 'neckInjury',
    label: 'neckInjury'
  },
  {
    id: 'backInjury',
    label: 'backInjury'
  }
]

const INJURY_TYPE_OPTIONS = [
  {
    id: 'wounds/injury',
    label: 'wounds/injury'
  },
  {
    id: 'pain',
    label: 'pain'
  }
]

const ASSUMED_INJURY_OPTIONS = [
  {
    id: 'fracture',
    label: 'fracture'
  },
  {
    id: 'dislocated',
    label: 'dislocated'
  },
  {
    id: 'sprain',
    label: 'sprain'
  },
  {
    id: 'impactShock',
    label: 'impact/shock'
  },
  {
    id: 'cutsWounds',
    label: 'cuts/wounds'
  },
  {
    id: 'concussion',
    label: 'concussion'
  },
  {
    id: 'acuteSickness',
    label: 'acuteSickness'
  },
  {
    id: 'dead',
    label: 'dead'
  }
]

const INJURED_BODY_PART_OPTIONS = [
  {
    id: 'head',
    label: 'head'
  },
  {
    id: 'throat',
    label: 'throat'
  },
  {
    id: 'neck',
    label: 'neck'
  },
  {
    id: 'shoulder',
    label: 'shoulder'
  },
  {
    id: 'arm',
    label: 'arm'
  },
  {
    id: 'wrist',
    label: 'wrist'
  },
  {
    id: 'hand',
    label: 'hand'
  },
  {
    id: 'stomach',
    label: 'stomach'
  },
  {
    id: 'breast',
    label: 'breast'
  },
  {
    id: 'back',
    label: 'back'
  },
  {
    id: 'buttock',
    label: 'buttock'
  },
  {
    id: 'thigh',
    label: 'thigh'
  },
  {
    id: 'knee',
    label: 'knee'
  },
  {
    id: 'calf',
    label: 'calf'
  },
  {
    id: 'ankleFoot',
    label: 'ankle/foot'
  },
  { id: 'genitalia', label: 'genitalia' },
  { id: 'pelvis', label: 'pelvis' },
  { id: 'face', label: 'face' }
]

const INJURED_SUMMER_BODY_PART_OPTIONS = [
  {
    id: 'head',
    label: 'head'
  },
  {
    id: 'throat',
    label: 'throat'
  },
  {
    id: 'neck',
    label: 'neck'
  },
  {
    id: 'shoulder',
    label: 'shoulder'
  },
  {
    id: 'arm',
    label: 'arm'
  },
  {
    id: 'wrist',
    label: 'wrist'
  },
  {
    id: 'hand',
    label: 'hand'
  },
  {
    id: 'stomach',
    label: 'stomach'
  },
  {
    id: 'breast',
    label: 'breast'
  },
  {
    id: 'back',
    label: 'back'
  },
  {
    id: 'buttock',
    label: 'buttock'
  },
  {
    id: 'collarbone',
    label: 'collarbone'
  },
  {
    id: 'hip',
    label: 'hip'
  },
  {
    id: 'thigh',
    label: 'thigh'
  },
  {
    id: 'knee',
    label: 'knee'
  },
  {
    id: 'calf',
    label: 'calf'
  },
  {
    id: 'ankleFoot',
    label: 'ankle/foot'
  },
  { id: 'genitalia', label: 'genitalia' },
  { id: 'pelvis', label: 'pelvis' },
  { id: 'face', label: 'face' }
]

const CONDITION_OPTIONS = [
  {
    id: 'Unchanged',
    label: 'unchanged'
  },
  {
    id: 'Better',
    label: 'better'
  },
  {
    id: 'Worse',
    label: 'worse'
  }
]

const MONITORING_EXAMINATION_OPTIONS = [
  {
    id: 'No',
    label: 'no'
  },
  {
    id: 'Partly',
    label: 'partly'
  },
  {
    id: 'Yes, no finds',
    label: 'yesNoFinds'
  },
  {
    id: 'Yes, with finds',
    label: 'yesWithFinds'
  }
]

const TREATED_BY_OPTIONS = [
  {
    id: 'skiPatrol',
    label: 'skiPatrol'
  },
  {
    id: 'otherEmployee',
    label: 'otherEmployee'
  },
  {
    id: 'contactDoctorIfConditionsWorsens',
    label: 'contactDoctorIfConditionWorsens'
  },
  {
    id: 'firstAidTrained',
    label: 'firstAidTrained'
  }
]

const TREATED_BY_SUMMER_OPTIONS = [
  {
    id: 'pathPatrol',
    label: 'pathPatrol'
  },
  {
    id: 'otherEmployee',
    label: 'otherEmployeeBike'
  },
  {
    id: 'emergencyRoom',
    label: 'emergencyRoom'
  },
  {
    id: 'ambulanceOnSite',
    label: 'ambulanceOnSite'
  },
  {
    id: 'doctorOnSite',
    label: 'doctorOnSite'
  },
  {
    id: 'seeDoctorIfConditionsWorsens',
    label: 'seeDoctorIfConditionWorsens'
  },
  {
    id: 'firstAidTrained',
    label: 'firstAidTrained'
  }
]

const TRANSPORT_AT_RESORT_OPTIONS = [
  {
    id: 'own',
    label: 'own'
  },
  {
    id: 'pulk',
    label: 'pulk'
  },
  {
    id: 'snowScooter',
    label: 'snowScooter'
  },
  {
    id: 'scooterPulk',
    label: 'scooterPulk'
  },
  {
    id: 'atv',
    label: 'atv'
  }
]
const TRANSPORT_AT_RESORT_SUMMER_OPTIONS = [
  {
    id: 'walkingCyclingYourself',
    label: 'walkingCyclingYourself'
  },
  {
    id: 'stretcher',
    label: 'stretcher'
  },
  {
    id: 'fireAndRescue',
    label: 'fireAndRescue'
  },
  {
    id: 'atvUtv',
    label: 'atvUtv'
  }
]

const FURTHER_TRANSPORT_OPTIONS = [
  {
    id: 'airAmbulance',
    label: 'airAmbulance'
  },
  {
    id: 'ambulance',
    label: 'ambulance'
  },
  {
    id: 'taxi',
    label: 'taxi'
  },
  {
    id: 'privateCar',
    label: 'privateCar'
  },
  {
    id: 'stayingAtSkiResort',
    label: 'stayingAtSkiResort'
  }
]

const FURTHER_TRANSPORT_SUMMER_OPTIONS = [
  {
    id: 'airAmbulance',
    label: 'airAmbulance'
  },
  {
    id: 'ambulance',
    label: 'ambulance'
  },
  {
    id: 'taxi',
    label: 'taxi'
  },
  {
    id: 'privateCar',
    label: 'privateCar'
  },
  {
    id: 'stayingAtSkiResort',
    label: 'stayingAtSkiResort'
  },
  {
    id: 'stiPTrspEmergencyRoom',
    label: 'stiPTrspEmergencyRoom'
  }
]

const CHECKLIST_OPTIONS = [
  {
    id: 'clearAirways',
    label: 'freeAirways'
  },
  {
    id: 'hlr',
    label: 'HLR'
  },
  {
    id: 'bandaging',
    label: 'bandaging'
  },
  {
    id: 'fracture',
    label: 'fracture'
  },
  {
    id: 'splint',
    label: 'splint'
  },
  {
    id: 'stretchSplint',
    label: 'stretchSplint'
  },
  {
    id: 'vacuumMattress',
    label: 'vacuumMattress'
  },
  {
    id: 'backboard',
    label: 'backboard'
  },
  {
    id: 'fallForward',
    label: 'fallForward'
  },
  { id: 'externalHeat', label: 'externalHeat' }
]

const CHECKLIST_SUMMER_OPTIONS = [
  {
    id: 'clearAirways',
    label: 'freeAirways'
  },
  {
    id: 'hlr',
    label: 'HLR'
  },
  {
    id: 'bandaging',
    label: 'bandaging'
  },
  {
    id: 'splint',
    label: 'splint'
  },
  {
    id: 'reportFracture',
    label: 'reportFracture'
  },
  {
    id: 'fracture',
    label: 'fracture'
  },
  {
    id: 'stretchSplint',
    label: 'stretchSplint'
  },
  {
    id: 'vacuumMattress',
    label: 'vacuumMattress'
  },
  {
    id: 'backboard',
    label: 'backboard'
  },
  {
    id: 'woundCleanser',
    label: 'woundCleanser'
  },
  {
    id: 'fallForward',
    label: 'fallForward'
  },
  { id: 'externalHeat', label: 'externalHeat' }
]

const COUNTRY_OPTIONS = [
  { label: '', id: '' },
  { label: 'Sweden', id: 'Sweden' },
  { label: 'Afghanistan', id: 'Afghanistan' },
  { label: 'Åland Islands', id: 'Åland Islands' },
  { label: 'Albania', id: 'Albania' },
  { label: 'Algeria', id: 'Algeria' },
  { label: 'American Samoa', id: 'American Samoa' },
  { label: 'Andorra', id: 'Andorra' },
  { label: 'Angola', id: 'Angola' },
  { label: 'Anguilla', id: 'Anguilla' },
  { label: 'Antarctica', id: 'Antarctica' },
  { label: 'Antigua and Barbuda', id: 'Antigua and Barbuda' },
  { label: 'Argentina', id: 'Argentina' },
  { label: 'Armenia', id: 'Armenia' },
  { label: 'Aruba', id: 'Aruba' },
  { label: 'Australia', id: 'Australia' },
  { label: 'Austria', id: 'Austria' },
  { label: 'Azerbaijan', id: 'Azerbaijan' },
  { label: 'Bahamas', id: 'Bahamas' },
  { label: 'Bahrain', id: 'Bahrain' },
  { label: 'Bangladesh', id: 'Bangladesh' },
  { label: 'Barbados', id: 'Barbados' },
  { label: 'Belarus', id: 'Belarus' },
  { label: 'Belgium', id: 'Belgium' },
  { label: 'Belize', id: 'Belize' },
  { label: 'Benin', id: 'Benin' },
  { label: 'Bermuda', id: 'Bermuda' },
  { label: 'Bhutan', id: 'Bhutan' },
  { label: 'Bolivia', id: 'Bolivia' },
  { label: 'Bosnia and Herzegovina', id: 'Bosnia and Herzegovina' },
  { label: 'Botswana', id: 'Botswana' },
  { label: 'Bouvet Island', id: 'Bouvet Island' },
  { label: 'Brazil', id: 'Brazil' },
  {
    label: 'British Indian Ocean Territory',
    id: 'British Indian Ocean Territory'
  },
  { label: 'Brunei Darussalam', id: 'Brunei Darussalam' },
  { label: 'Bulgaria', id: 'Bulgaria' },
  { label: 'Burkina Faso', id: 'Burkina Faso' },
  { label: 'Burundi', id: 'Burundi' },
  { label: 'Cambodia', id: 'Cambodia' },
  { label: 'Cameroon', id: 'Cameroon' },
  { label: 'Canada', id: 'Canada' },
  { label: 'Cape Verde', id: 'Cape Verde' },
  { label: 'Cayman Islands', id: 'Cayman Islands' },
  { label: 'Central African Republic', id: 'Central African Republic' },
  { label: 'Chad', id: 'Chad' },
  { label: 'Chile', id: 'Chile' },
  { label: 'China', id: 'China' },
  { label: 'Christmas Island', id: 'Christmas Island' },
  { label: 'Cocos (Keeling) Islands', id: 'Cocos (Keeling) Islands' },
  { label: 'Colombia', id: 'Colombia' },
  { label: 'Comoros', id: 'Comoros' },
  { label: 'Congo', id: 'Congo' },
  {
    label: 'Congo, The Democratic Republic of the',
    id: 'Congo, The Democratic Republic of the'
  },
  { label: 'Cook Islands', id: 'Cook Islands' },
  { label: 'Costa Rica', id: 'Costa Rica' },
  { label: "Cote D'Ivoire", id: "Cote D'Ivoire" },
  { label: 'Croatia', id: 'Croatia' },
  { label: 'Cuba', id: 'Cuba' },
  { label: 'Cyprus', id: 'Cyprus' },
  { label: 'Czech Republic', id: 'Czech Republic' },
  { label: 'Denmark', id: 'Denmark' },
  { label: 'Djibouti', id: 'Djibouti' },
  { label: 'Dominica', id: 'Dominica' },
  { label: 'Dominican Republic', id: 'Dominican Republic' },
  { label: 'Ecuador', id: 'Ecuador' },
  { label: 'Egypt', id: 'Egypt' },
  { label: 'El Salvador', id: 'El Salvador' },
  { label: 'Equatorial Guinea', id: 'Equatorial Guinea' },
  { label: 'Eritrea', id: 'Eritrea' },
  { label: 'Estonia', id: 'Estonia' },
  { label: 'Ethiopia', id: 'Ethiopia' },
  {
    label: 'Falkland Islands (Malvinas)',
    id: 'Falkland Islands (Malvinas)'
  },
  { label: 'Faroe Islands', id: 'Faroe Islands' },
  { label: 'Fiji', id: 'Fiji' },
  { label: 'Finland', id: 'Finland' },
  { label: 'France', id: 'France' },
  { label: 'French Guiana', id: 'French Guiana' },
  { label: 'French Polynesia', id: 'French Polynesia' },
  {
    label: 'French Southern Territories',
    id: 'French Southern Territories'
  },
  { label: 'Gabon', id: 'Gabon' },
  { label: 'Gambia', id: 'Gambia' },
  { label: 'Georgia', id: 'Georgia' },
  { label: 'Germany', id: 'Germany' },
  { label: 'Ghana', id: 'Ghana' },
  { label: 'Gibraltar', id: 'Gibraltar' },
  { label: 'Greece', id: 'Greece' },
  { label: 'Greenland', id: 'Greenland' },
  { label: 'Grenada', id: 'Grenada' },
  { label: 'Guadeloupe', id: 'Guadeloupe' },
  { label: 'Guam', id: 'Guam' },
  { label: 'Guatemala', id: 'Guatemala' },
  { label: 'Guernsey', id: 'Guernsey' },
  { label: 'Guinea', id: 'Guinea' },
  { label: 'Guinea-Bissau', id: 'Guinea-Bissau' },
  { label: 'Guyana', id: 'Guyana' },
  { label: 'Haiti', id: 'Haiti' },
  {
    label: 'Heard Island and Mcdonald Islands',
    id: 'Heard Island and Mcdonald Islands'
  },
  {
    label: 'Holy See (Vatican City State)',
    id: 'Holy See (Vatican City State)'
  },
  { label: 'Honduras', id: 'Honduras' },
  { label: 'Hong Kong', id: 'Hong Kong' },
  { label: 'Hungary', id: 'Hungary' },
  { label: 'Iceland', id: 'Iceland' },
  { label: 'India', id: 'India' },
  { label: 'Indonesia', id: 'Indonesia' },
  { label: 'Iran, Islamic Republic Of', id: 'Iran, Islamic Republic Of' },
  { label: 'Iraq', id: 'Iraq' },
  { label: 'Ireland', id: 'Ireland' },
  { label: 'Isle of Man', id: 'Isle of Man' },
  { label: 'Israel', id: 'Israel' },
  { label: 'Italy', id: 'Italy' },
  { label: 'Jamaica', id: 'Jamaica' },
  { label: 'Japan', id: 'Japan' },
  { label: 'Jersey', id: 'Jersey' },
  { label: 'Jordan', id: 'Jordan' },
  { label: 'Kazakhstan', id: 'Kazakhstan' },
  { label: 'Kenya', id: 'Kenya' },
  { label: 'Kiribati', id: 'Kiribati' },
  {
    label: "Korea, Democratic People'S Republic of",
    id: "Korea, Democratic People'S Republic of"
  },
  { label: 'Korea, Republic of', id: 'Korea, Republic of' },
  { label: 'Kuwait', id: 'Kuwait' },
  { label: 'Kyrgyzstan', id: 'Kyrgyzstan' },
  {
    label: "Lao People'S Democratic Republic",
    id: "Lao People'S Democratic Republic"
  },
  { label: 'Latvia', id: 'Latvia' },
  { label: 'Lebanon', id: 'Lebanon' },
  { label: 'Lesotho', id: 'Lesotho' },
  { label: 'Liberia', id: 'Liberia' },
  { label: 'Libyan Arab Jamahiriya', id: 'Libyan Arab Jamahiriya' },
  { label: 'Liechtenstein', id: 'Liechtenstein' },
  { label: 'Lithuania', id: 'Lithuania' },
  { label: 'Luxembourg', id: 'Luxembourg' },
  { label: 'Macao', id: 'Macao' },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    id: 'Macedonia, The Former Yugoslav Republic of'
  },
  { label: 'Madagascar', id: 'Madagascar' },
  { label: 'Malawi', id: 'Malawi' },
  { label: 'Malaysia', id: 'Malaysia' },
  { label: 'Maldives', id: 'Maldives' },
  { label: 'Mali', id: 'Mali' },
  { label: 'Malta', id: 'Malta' },
  { label: 'Marshall Islands', id: 'Marshall Islands' },
  { label: 'Martinique', id: 'Martinique' },
  { label: 'Mauritania', id: 'Mauritania' },
  { label: 'Mauritius', id: 'Mauritius' },
  { label: 'Mayotte', id: 'Mayotte' },
  { label: 'Mexico', id: 'Mexico' },
  {
    label: 'Micronesia, Federated States of',
    id: 'Micronesia, Federated States of'
  },
  { label: 'Moldova, Republic of', id: 'Moldova, Republic of' },
  { label: 'Monaco', id: 'Monaco' },
  { label: 'Mongolia', id: 'Mongolia' },
  { label: 'Montenegro', id: 'Montenegro' },
  { label: 'Montserrat', id: 'Montserrat' },
  { label: 'Morocco', id: 'Morocco' },
  { label: 'Mozambique', id: 'Mozambique' },
  { label: 'Myanmar', id: 'Myanmar' },
  { label: 'Namibia', id: 'Namibia' },
  { label: 'Nauru', id: 'Nauru' },
  { label: 'Nepal', id: 'Nepal' },
  { label: 'Netherlands', id: 'Netherlands' },
  { label: 'Netherlands Antilles', id: 'Netherlands Antilles' },
  { label: 'New Caledonia', id: 'New Caledonia' },
  { label: 'New Zealand', id: 'New Zealand' },
  { label: 'Nicaragua', id: 'Nicaragua' },
  { label: 'Niger', id: 'Niger' },
  { label: 'Nigeria', id: 'Nigeria' },
  { label: 'Niue', id: 'Niue' },
  { label: 'Norfolk Island', id: 'Norfolk Island' },
  { label: 'Northern Mariana Islands', id: 'Northern Mariana Islands' },
  { label: 'Norway', id: 'Norway' },
  { label: 'Oman', id: 'Oman' },
  { label: 'Pakistan', id: 'Pakistan' },
  { label: 'Palau', id: 'Palau' },
  {
    label: 'Palestinian Territory, Occupied',
    id: 'Palestinian Territory, Occupied'
  },
  { label: 'Panama', id: 'Panama' },
  { label: 'Papua New Guinea', id: 'Papua New Guinea' },
  { label: 'Paraguay', id: 'Paraguay' },
  { label: 'Peru', id: 'Peru' },
  { label: 'Philippines', id: 'Philippines' },
  { label: 'Pitcairn', id: 'Pitcairn' },
  { label: 'Poland', id: 'Poland' },
  { label: 'Portugal', id: 'Portugal' },
  { label: 'Puerto Rico', id: 'Puerto Rico' },
  { label: 'Qatar', id: 'Qatar' },
  { label: 'Reunion', id: 'Reunion' },
  { label: 'Romania', id: 'Romania' },
  { label: 'Russian Federation', id: 'Russian Federation' },
  { label: 'RWANDA', id: 'RWANDA' },
  { label: 'Saint Helena', id: 'Saint Helena' },
  { label: 'Saint Kitts and Nevis', id: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', id: 'Saint Lucia' },
  { label: 'Saint Pierre and Miquelon', id: 'Saint Pierre and Miquelon' },
  {
    label: 'Saint Vincent and the Grenadines',
    id: 'Saint Vincent and the Grenadines'
  },
  { label: 'Samoa', id: 'Samoa' },
  { label: 'San Marino', id: 'San Marino' },
  { label: 'Sao Tome and Principe', id: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', id: 'Saudi Arabia' },
  { label: 'Senegal', id: 'Senegal' },
  { label: 'Serbia', id: 'Serbia' },
  { label: 'Seychelles', id: 'Seychelles' },
  { label: 'Sierra Leone', id: 'Sierra Leone' },
  { label: 'Singapore', id: 'Singapore' },
  { label: 'Slovakia', id: 'Slovakia' },
  { label: 'Slovenia', id: 'Slovenia' },
  { label: 'Solomon Islands', id: 'Solomon Islands' },
  { label: 'Somalia', id: 'Somalia' },
  { label: 'South Africa', id: 'South Africa' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    id: 'South Georgia and the South Sandwich Islands'
  },
  { label: 'Spain', id: 'Spain' },
  { label: 'Sri Lanka', id: 'LSri LankaK' },
  { label: 'Sudan', id: 'Sudan' },
  { label: 'Surilabel', id: 'Surilabel' },
  { label: 'Svalbard and Jan Mayen', id: 'Svalbard and Jan Mayen' },
  { label: 'Swaziland', id: 'Swaziland' },
  { label: 'Switzerland', id: 'Switzerland' },
  { label: 'Syrian Arab Republic', id: 'Syrian Arab Republic' },
  { label: 'Taiwan, Province of China', id: 'Taiwan, Province of China' },
  { label: 'Tajikistan', id: 'TTajikistanJ' },
  {
    label: 'Tanzania, United Republic of',
    id: 'Tanzania, United Republic of'
  },
  { label: 'Thailand', id: 'Thailand' },
  { label: 'Timor-Leste', id: 'Timor-Leste' },
  { label: 'Togo', id: 'Togo' },
  { label: 'Tokelau', id: 'Tokelau' },
  { label: 'Tonga', id: 'Tonga' },
  { label: 'Trinidad and Tobago', id: 'Trinidad and Tobago' },
  { label: 'Tunisia', id: 'Tunisia' },
  { label: 'Turkey', id: 'Turkey' },
  { label: 'Turkmenistan', id: 'Turkmenistan' },
  { label: 'Turks and Caicos Islands', id: 'Turks and Caicos Islands' },
  { label: 'Tuvalu', id: 'Tuvalu' },
  { label: 'Uganda', id: 'Uganda' },
  { label: 'Ukraine', id: 'Ukraine' },
  { label: 'United Arab Emirates', id: 'United Arab Emirates' },
  { label: 'United Kingdom', id: 'GUnited KingdomB' },
  { label: 'United States', id: 'United States' },
  {
    label: 'United States Minor Outlying Islands',
    id: 'United States Minor Outlying Islands'
  },
  { label: 'Uruguay', id: 'Uruguay' },
  { label: 'Uzbekistan', id: 'Uzbekistan' },
  { label: 'Vanuatu', id: 'Vanuatu' },
  { label: 'Venezuela', id: 'Venezuela' },
  { label: 'Viet Nam', id: 'Viet Nam' },
  { label: 'Virgin Islands, British', id: 'Virgin Islands, British' },
  { label: 'Virgin Islands, U.S.', id: 'Virgin Islands, U.S.' },
  { label: 'Wallis and Futuna', id: 'Wallis and Futuna' },
  { label: 'Western Sahara', id: 'Western Sahara' },
  { label: 'Yemen', id: 'Yemen' },
  { label: 'Zambia', id: 'Zambia' },
  { label: 'Zimbabwe', id: 'Zimbabwe' }
]

export {
  ASSUMED_INJURY_OPTIONS,
  BIKE_EQUIPMENT_OPTIONS,
  BLEEDINGS_SOURCE_OPTIONS,
  BOOLEAN_OPTIONS,
  BREATHING_OPTIONS,
  CHECKLIST_OPTIONS,
  CHECKLIST_SUMMER_OPTIONS,
  CIRCULATION_OPTIONS,
  CONDITION_OPTIONS,
  CONSCIOUSNESS_OPTIONS,
  COUNTRY_OPTIONS,
  FURTHER_TRANSPORT_OPTIONS,
  FURTHER_TRANSPORT_SUMMER_OPTIONS,
  GENDER_OPTIONS,
  HIGH_ENERGY_OPTIONS,
  INJURED_BODY_PART_OPTIONS,
  INJURED_SUMMER_BODY_PART_OPTIONS,
  INJURY_LOCATION_OPTIONS,
  INJURY_LOCATION_SUMMER_OPTIONS,
  INJURY_TYPE_OPTIONS,
  IS_RENTED_OPTIONS,
  MONITORING_EXAMINATION_OPTIONS,
  POSIBLE_INJURY_OPTIONS,
  PULSE_OPTIONS,
  PULSE_SOURCE_OPTIONS,
  RELATIVE_OPTIONS,
  SEQUENCE_OF_EVENTS_SUMMER,
  SEQUENCE_OF_EVENTS_WINTER,
  SKILL_LEVEL_OPTIONS,
  SKI_EQUIPMENT_OPTIONS,
  TRANSPORT_AT_RESORT_OPTIONS,
  TRANSPORT_AT_RESORT_SUMMER_OPTIONS,
  TREATED_BY_OPTIONS,
  TREATED_BY_SUMMER_OPTIONS
}
