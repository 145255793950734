import { IconCheck, IconCircleX, IconEdit, IconPrint } from 'assets/images'
import axios from 'axios'
import {
  Loading,
  MaterialModal,
  PageHeader,
  Spinner,
  TimeAndDate
} from 'components'
import { Formik } from 'formik'
import { useRole } from 'hooks'
import { t } from 'i18n'
import fileDownload from 'js-file-download'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { If } from 'react-extras'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Dispatch, bindActionCreators } from 'redux'
import {
  GetReportById,
  createNewVersion,
  deleteReport,
  getReportPdf
} from 'services'
import { Report, ReportStatus, Role } from 'store/ducks'
import * as ReportActions from 'store/ducks/report/actions'
import DropdownFilter from '../../containers/Statistics/Filters/DropdownFilter'
import { ApplicationState } from '../../store'
import AllFiles from '../Report/components/report-header/AllFiles'
import ReportOverView from './report-overview'
import {
  BUTTON_COLOR,
  ButtonsContainer,
  PrintButtonContainer,
  ReportOverViewContainer,
  ReportOverviewActionContainer,
  ReportOverviewHeaderContainer,
  StyledButton
} from './styled'

const ReportOverviewPage = () => {
  const [report, setReport] = useState<Report>(undefined as unknown as Report)
  const [loading, setLoading] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<boolean>(false)
  const printComponent = useRef<HTMLDivElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedVersion, setSelectedVersion] = useState<number>()
  const [latestVersion, setLatestVersion] = useState<number>()
  const [versions, setVersions] = useState<ItemProps[]>([])

  const { reportId } = useParams()

  const navigate = useNavigate()

  const isAdmin = useRole([Role.SYS_ADMIN, Role.RESORT_ADMINISTRATOR])

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const configureVersions = useCallback((_report: Report) => {
    const relatedReportVersion = _report?.relatedReports?.filter(
      x => x.type === 'VERSION'
    )

    if (relatedReportVersion && relatedReportVersion.length > 0) {
      const versionsNumber: ItemProps[] =
        (relatedReportVersion &&
          relatedReportVersion.map(rep => ({
            label: `${t('version')}: ${rep.version}`,
            value: rep.version
          }))) ||
        []

      if (_report?.version) {
        versionsNumber.push({
          label: `${t('version')}: ${_report?.version}`,
          value: _report?.version
        })
      }
      setVersions(versionsNumber || [])
    }
    setSelectedVersion(_report?.version)
    setLatestVersion(_report?.version)
  }, [])

  const getReport = useCallback(
    async (reportId: number) => {
      setLoading(true)
      const _report = await GetReportById(reportId)
      configureVersions(_report)
      setReport(_report)
      setLoading(false)
    },
    [configureVersions]
  )

  useEffect(() => {
    if (reportId) {
      getReport(Number(reportId))
    } else {
      navigate('/')
    }
  }, [getReport, navigate, reportId])

  const createNewReportVersion = async () => {
    setLoading(true)
    const newReportVersion = await createNewVersion(report)
    setLoading(false)
    navigate(`/reports/edit/${newReportVersion?.reportId}`)
  }

  const downloadFile = async () => {
    setDownloading(true)
    const { url } = await getReportPdf(Number(report.reportId))

    axios
      .get(url, {
        responseType: 'blob'
      })
      .then(res => {
        fileDownload(res.data, `skaderapportering-${report.reportId}.pdf`)
        setDownloading(false)
      })
      .catch(() => {
        setDownloading(false)
      })
  }

  const editReport = () => {
    if (report?.reportStatus === ReportStatus.PUBLISHED) {
      createNewReportVersion()
    } else {
      navigate(`/reports/edit/${report?.reportId}`)
    }
  }

  const handleDeleteReport = async () => {
    if (!report?.reportId) return

    try {
      await deleteReport(report.reportId)
      toast.success(t('reportDeletedSuccess', { value: report.SK }))
      navigate('/reports')
    } catch (error) {
      console.error(error)
    }
  }

  const handleVersionChange = async (e: React.FormEvent<HTMLSelectElement>) => {
    setLoading(true)

    const { value } = e.currentTarget

    const version = Number(value)

    setSelectedVersion(version)

    const _version = latestVersion !== version ? version : undefined

    const _report = await GetReportById(Number(reportId), _version)

    setReport(_report)

    setLoading(false)
  }

  return (
    <Fragment>
      <ReportOverViewContainer>
        <ReportOverviewHeaderContainer>
          <PageHeader title={t('viewingReport')}>
            <TimeAndDate
              value={
                report?.reportDate ? new Date(report?.reportDate) : new Date()
              }
            />
          </PageHeader>
        </ReportOverviewHeaderContainer>

        <If condition={!loading && latestVersion === report?.version}>
          <ReportOverviewActionContainer>
            <ButtonsContainer>
              <If
                condition={
                  report && report?.reportStatus !== ReportStatus.PUBLISHED
                    ? true
                    : false
                }
              >
                <StyledButton onClick={editReport} $bgColor={BUTTON_COLOR.BLUE}>
                  {t('editReport')}
                  <IconEdit fill="#0072BB" />
                </StyledButton>
              </If>
              <If
                condition={
                  report && report?.reportStatus === ReportStatus.PUBLISHED
                    ? true
                    : false
                }
              >
                <StyledButton onClick={editReport} $bgColor={BUTTON_COLOR.BLUE}>
                  {t('newVersion')}
                  <IconEdit fill="#0072BB" />
                </StyledButton>
              </If>
              <PrintButtonContainer>
                <StyledButton
                  disabled={downloading}
                  onClick={downloadFile}
                  $bgColor={BUTTON_COLOR.BLUE}
                  $bordered
                >
                  {t('print')}
                  <IconPrint fill="#0072BB" />
                </StyledButton>
                <If condition={downloading}>
                  <Spinner style={{ marginLeft: 10 }} />
                </If>
              </PrintButtonContainer>
            </ButtonsContainer>
            <If condition={isAdmin}>
              <ButtonsContainer>
                <MaterialModal isOpen={openModal} onClose={handleCloseModal}>
                  <h3>{t('deleteReportWarning')}</h3>
                  <ButtonsContainer>
                    <StyledButton
                      onClick={handleDeleteReport}
                      $bgColor={BUTTON_COLOR.RED}
                      $bordered
                    >
                      {t('confirm')}
                      <IconCheck fill="#0072BB" />
                    </StyledButton>
                    <StyledButton
                      onClick={handleCloseModal}
                      $bgColor={BUTTON_COLOR.BLUE}
                    >
                      {t('cancel')}
                      <IconCircleX fill="#0072BB" />
                    </StyledButton>
                  </ButtonsContainer>
                </MaterialModal>
                <StyledButton
                  onClick={handleOpenModal}
                  $bgColor={BUTTON_COLOR.RED}
                  $bordered
                >
                  {t('deleteReport')}
                  <IconCircleX fill="#0072BB" />
                </StyledButton>
                <Formik initialValues={report || {}} onSubmit={() => {}}>
                  <AllFiles />
                </Formik>
              </ButtonsContainer>
            </If>
          </ReportOverviewActionContainer>
        </If>
        <If condition={versions && versions.length > 0}>
          <ReportOverviewActionContainer>
            <DropdownFilter
              $border={true}
              items={versions}
              name="version"
              title={t('version')}
              onChange={handleVersionChange}
              value={String(selectedVersion)}
            />
          </ReportOverviewActionContainer>
        </If>

        <If condition={!loading && report ? true : false}>
          <ReportOverView ref={printComponent} report={report} />
        </If>
        <If condition={loading}>
          <Loading style={{ height: '30vh' }} />
        </If>
      </ReportOverViewContainer>
    </Fragment>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  reportData: state.report,
  auth: state.auth
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReportActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReportOverviewPage)
