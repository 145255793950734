import { Button, DateTimePicker, RadioGroupInput, RangeInput } from 'components'
import {
  InputTimeContainer,
  InputTimeGroup,
  InputTimeLabel
} from 'components/form-fields/styled'
import {
  CONDITION_OPTIONS,
  MONITORING_EXAMINATION_OPTIONS
} from 'constants/options'
import { t } from 'i18n'
import { ChangeEvent, useEffect, useState } from 'react'
import { Monitoring, MonitoringExamination, MonitoringState } from 'store/ducks'
import {
  FormSectionSubtitle,
  RowItem
} from '../../pages/Report/components/form-components/styled'

interface IMonitoringProps {
  monitoring: Monitoring
  onChangeData: (monitoring: Monitoring) => void
  saveMonitoring: () => void
}

interface IMonitoringInput {
  label: string
  name: string
  value?: string | number
  onChange: (key: string, value: string | number) => void
}

const MonitoringField = ({
  label,
  name,
  value,
  onChange
}: IMonitoringInput) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    onChange(name, value)
  }
  return (
    <InputTimeGroup>
      <InputTimeLabel style={{ margin: '20px 0' }}>{t(label)}</InputTimeLabel>
      <InputTimeContainer
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </InputTimeGroup>
  )
}

const MonitoringForm = ({
  monitoring,
  onChangeData,
  saveMonitoring
}: IMonitoringProps) => {
  const [update, setUpdate] = useState<boolean>(false)

  useEffect(() => {}, [update])

  const handleChangeRadio = (key: string, value: string | boolean) => {
    switch (key) {
      case 'state':
        monitoring.state = String(value) as MonitoringState
        break
      case 'conductedExamination':
        monitoring.conductedExamination = value as MonitoringExamination
        break
    }
    onChangeData(monitoring)
    setUpdate(!update)
  }

  const handleChangeInput = (key: string, value: string | number) => {
    switch (key) {
      case 'breathFrequency':
        monitoring.breathFrequency = String(value)
        break
      case 'glasgowScore':
        monitoring.glasgowScore = String(value)
        break
      case 'pulse':
        monitoring.pulse = String(value)
        break
      case 'pain':
        monitoring.pain = Number(value)
        break
    }

    onChangeData(monitoring)
    setUpdate(!update)
  }

  return (
    <>
      <FormSectionSubtitle>{t`theTimeIs`}</FormSectionSubtitle>
      <DateTimePicker
        timeOnly
        timeIntervals={1}
        dateFormat={'HH:mm'}
        onChange={value => {
          monitoring.monitoringHour = value
          onChangeData(monitoring)
          setUpdate(!update)
        }}
        value={monitoring?.monitoringHour ?? new Date()}
      />
      <FormSectionSubtitle>{t`condition`}</FormSectionSubtitle>
      <RadioGroupInput
        name="state"
        value={monitoring?.state}
        options={CONDITION_OPTIONS}
        onChange={handleChangeRadio}
      />
      <FormSectionSubtitle>
        {t`conductedPassiveExamination`}
      </FormSectionSubtitle>
      <RadioGroupInput
        name="conductedExamination"
        value={monitoring?.conductedExamination}
        options={MONITORING_EXAMINATION_OPTIONS}
        onChange={handleChangeRadio}
      />
      <FormSectionSubtitle>{t`temperatureMeasurement`}</FormSectionSubtitle>
      <RowItem style={{ maxWidth: '40vw' }}>
        <RangeInput
          name="monitoring.bodyTemperature"
          label="patientTemperature"
          value={monitoring.bodyTemperature ?? 20}
          onChange={(name, value) => {
            monitoring.bodyTemperature = Number(value)

            onChangeData(monitoring)
            setUpdate(!update)
          }}
          marks={0}
          min={20}
          max={42}
          step={0.1}
        />
      </RowItem>
      <FormSectionSubtitle>{t`monitoring`}</FormSectionSubtitle>
      <MonitoringField
        label="respiratoryRate"
        name="breathFrequency"
        value={monitoring.breathFrequency}
        onChange={handleChangeInput}
      />
      <MonitoringField
        label="glascowScore"
        name="glasgowScore"
        value={monitoring.glasgowScore}
        onChange={handleChangeInput}
      />
      <MonitoringField
        label="pulseSP02"
        name="pulse"
        value={monitoring.pulse}
        onChange={handleChangeInput}
      />
      <MonitoringField
        label="painScale"
        name="pain"
        value={monitoring.pain}
        onChange={(key, value) =>
          handleChangeInput(key, String(value).replace(/[^0-9]+/g, ''))
        }
      />
      <Button
        style={{ maxWidth: '21vw', marginTop: 40, marginBottom: 20 }}
        label="saveMonitoring"
        type="button"
        onClick={saveMonitoring}
      />
    </>
  )
}

export default MonitoringForm
