import { Metadata, Resort } from '../report/types'

export enum GetUsersTypes {
  GET_USERS_REQUEST = '@users/GET_USERS_REQUEST',
  GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS',
  GET_USERS_FAILURE = '@users/GET_USERS_FAILURE'
}

export enum Role {
  RESORT_ADMINISTRATOR = 'RESORT_ADMINISTRATOR',
  USER = 'USER',
  SYS_ADMIN = 'SYS_ADMIN'
}

export enum AgreementType {
  PRIVACY_POLICY = 'Privacy police',
  TERMS_AND_CONDITIONS = 'Terms and Conditions'
}

export interface Agreement {
  id: string
  accepted: boolean
  agreementType: AgreementType
  deviceId?: string
  metadata?: Metadata
  createdAt?: Date
  updatedAt?: Date
}

export type UserAccess = {
  startsOn: Date
  endsOn: Date
  role: Role
  default: boolean
}

export type User = {
  id: string
  firstName: string
  lastName: string
  numericId?: number
  phoneNumber: string
  email: string
  resortId?: string
  userStatus?: string
  resort?: Resort
  access: UserAccess[]
  agreements: Agreement[]
  createdAt?: Date
  updatedAt?: Date
  resortAccessGroup?: string
}

export interface UsersState {
  readonly data: User[]
  readonly loading: boolean
  readonly error: boolean
  readonly errorStack: any
}
