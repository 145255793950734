import { put } from 'redux-saga/effects'
import { getResort } from 'services'
import { Resort } from '../report/types'
import { getResortFailure, getResortRequest, getResortSuccess } from './actions'

export function* getResortSaga({
  payload
}: ReturnType<typeof getResortRequest>) {
  try {
    const response: Resort = yield getResort(payload)
    yield put(getResortSuccess(response))
  } catch (err: any) {
    yield put(getResortFailure(err))
  }
}
