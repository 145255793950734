import { IconEdit } from 'assets/images'
import { t } from 'i18n'
import { If } from 'react-extras'
import { Report, Seasons } from 'store/ducks'
import { ValidationSectionItem } from '../styled'

interface IValidateSectionProps {
  onClick: () => void
  data?: Report
  type: keyof Report
}

const ValidateSection = ({ onClick, data, type }: IValidateSectionProps) => {
  const missingProp = []
  if (!data) return null
  switch (type) {
    case 'personalInformation':
      if (
        data[type]?.firstName &&
        data[type]?.lastName &&
        data[type]?.phoneNumber &&
        data[type]?.birthDate &&
        data[type]?.gender &&
        data[type]?.nationality &&
        data[type]?.postalCode &&
        data[type]?.address &&
        data[type]?.place &&
        data[type]?.country &&
        data[type]?.relatives &&
        data[type]?.skills &&
        data[type]?.skills?.introduction &&
        data[type]?.equipments?.isRented &&
        data[type]?.equipments
      ) {
        return <></>
      }
      if (data[type]?.firstName === undefined) {
        missingProp.push('firstName')
      }
      if (data[type]?.lastName === undefined) {
        missingProp.push('lastName')
      }
      if (data[type]?.phoneNumber === undefined) {
        missingProp.push('phoneNumber')
      }
      if (data[type]?.gender === undefined) {
        missingProp.push('gender')
      }
      if (data[type]?.nationality === undefined) {
        missingProp.push('nationality')
      }
      if (data[type]?.postalCode === undefined) {
        missingProp.push('postalCode')
      }
      if (data[type]?.address === undefined) {
        missingProp.push('address')
      }
      if (data[type]?.place === undefined) {
        missingProp.push('place')
      }
      if (data[type]?.country === undefined) {
        missingProp.push('country')
      }
      if (data[type]?.relatives === undefined) {
        missingProp.push('relatives')
      }
      if (data[type]?.skills === undefined) {
        missingProp.push('skills')
      }
      if (data[type]?.skills?.introduction === undefined) {
        missingProp.push('injuryDuringInstructions')
      }
      if (
        data[type]?.equipments?.isRented === undefined &&
        data.season === Seasons.WINTER
      ) {
        missingProp.push('isRented')
      }
      if (data[type]?.equipments === undefined) {
        missingProp.push('skillsAndEquipment')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('personalInformation')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map((missing, index) => (
              <p key={`${missing}-${index}`} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'state':
      if (
        data[type]?.highEnergy &&
        data[type]?.consciousness &&
        data[type]?.bigBleedings &&
        data[type]?.breathing &&
        data[type]?.heartbeat &&
        data[type]?.pulse &&
        data[type]?.pulseSource &&
        data[type]?.painLevel &&
        data[type]?.circulation &&
        data[type]?.painLevelText
      ) {
        return null
      }
      if (data[type]?.highEnergy === undefined) {
        missingProp.push('highEnergy')
      }
      if (data[type]?.consciousness === undefined) {
        missingProp.push('consciousness')
      }
      if (data[type]?.bigBleedings === undefined) {
        missingProp.push('bigBleedings')
      }
      if (data[type]?.heartbeat === undefined) {
        missingProp.push('frequency')
      }
      if (!data[type]?.pulseSource) {
        missingProp.push('circulation')
      }
      if (data[type]?.painLevel === undefined) {
        missingProp.push('pain(VAS)')
      }
      if (data[type]?.circulation === undefined) {
        missingProp.push('circulation')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('state')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'timestamps':
      if (
        data[type]?.notificationOfInjuryTime &&
        (data[type]?.skiPatrolAtInjurySite ||
          data[type]?.pathPatrolAtInjurySite) &&
        data[type]?.pulkArrivedTime &&
        data[type]?.departureFromInjurySiteTime &&
        data[type]?.arrivedAtInjuryRoomTime &&
        data[type]?.amkWarningTime &&
        data[type]?.patientDeliveredTime
      ) {
        return null
      }
      if (data[type]?.notificationOfInjuryTime === undefined) {
        missingProp.push('notificationOfInjury')
      }
      if (data[type]?.skiPatrolAtInjurySite === undefined) {
        missingProp.push('skiPatrolOnInjurySite')
      }
      if (data[type]?.pulkArrivedTime === undefined) {
        missingProp.push('pulkArrived')
      }
      if (data[type]?.departureFromInjurySiteTime === undefined) {
        missingProp.push('departureFromInjurySite')
      }
      if (data[type]?.arrivedAtInjuryRoomTime === undefined) {
        missingProp.push('Arrived')
      }
      if (data[type]?.amkWarningTime === undefined) {
        missingProp.push('amkWarning')
      }
      if (data[type]?.patientDeliveredTime === undefined) {
        missingProp.push('patientDelivered')
      }
      return (
        <ValidationSectionItem>
          <IconEdit onClick={onClick} />
          <h4>{t('timeStamps')}</h4>
          <small>{t`notCompleted`}</small>
          {missingProp.map(missing => (
            <p key={missing} onClick={onClick}>
              {t(missing)}
            </p>
          ))}
        </ValidationSectionItem>
      )
    case 'sequenceOfEvents':
      if (
        data[type]?.injuryLocation &&
        data[type]?.injuryLocation?.injuryLocationOther &&
        data[type]?.eventExplanation &&
        data[type]?.eventExplanationLocation &&
        data[type]?.witnesses &&
        data[type]?.witnesses
      ) {
        return null
      }
      if (data[type]?.injuryLocation === undefined) {
        missingProp.push('whereDidTheInjuryOccur')
      }
      if (data[type]?.witnesses === undefined) {
        missingProp.push('witnesses')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('sequenceOfEvents')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'injury':
      if (
        data[type]?.assumedInjury &&
        data[type]?.injuredBodyPart &&
        data[type]?.injuryType
      ) {
        return null
      }
      if (data[type]?.assumedInjury === undefined) {
        missingProp.push('assumedInjury')
      }
      if (data[type]?.injuredBodyPart === undefined) {
        missingProp.push('injuredBodypart')
      }

      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('injury')}</h4>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'investigationAndMonitoring':
      if (data[type]?.monitoring) {
        return null
      }
      if (data[type]?.monitoring === undefined) {
        missingProp.push('surveillance')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('surveillance')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'treatedBy':
      if (data[type]) {
        return null
      }
      if (data[type] === undefined) {
        missingProp.push('treatedBy')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('treatedBy')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'transport':
      if (data[type]?.transportAtResort && data[type]?.furtherTransport) {
        return null
      }
      if (data[type]?.transportAtResort === undefined) {
        missingProp.push('atResort')
      }
      if (data[type]?.furtherTransport === undefined) {
        missingProp.push('furtherTransport')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('transport')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    case 'checklist':
      if (data[type]) {
        return null
      }
      if (data[type] === undefined) {
        missingProp.push('treatmentChecklist')
      }
      return (
        <If condition={missingProp.length > 0}>
          <ValidationSectionItem>
            <IconEdit onClick={onClick} />
            <h4>{t('treatmentChecklist')}</h4>
            <small>{t`notCompleted`}</small>
            {missingProp.map(missing => (
              <p key={missing} onClick={onClick}>
                {t(missing)}
              </p>
            ))}
          </ValidationSectionItem>
        </If>
      )
    default:
      return <></>
  }
}

export default ValidateSection
